/*
 * File: SimpleActions.module.css
 * Project: autobuy-back-office
 * File Created: Sunday, 14th January 2024 05:34:13
 * Author: Simon (s.gourlet@autobuy.fr)
 * -----
 * Last Modified: Sunday, 14th January 2024 05:36:25
 * Modified By: Simon (s.gourlet@autobuy.fr>)
 * -----
 * Copyright 2022 - 2024 SAS Olivier Dubois, SAS Olivier Dubois
 */



 .action {
    /* display: flex; */
    /* flex-direction: row; */
    /* flex-wrap: nowrap; */
    /* width: 100%;
    height: 100%; */
    color: lightblue;
    border: 0 solid white;
}
.action:hover {
    color: blue
}
.action:disabled {
    color: grey
}

.actionHolder{
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 100%;
}

