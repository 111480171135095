/*
 * File: Ligths.module.css
 * Project: autobuy-back-office
 * File Created: Thursday, 31st August 2023 04:43:15
 * Author: Simon (s.gourlet@autobuy.fr)
 * -----
 * Last Modified: Thursday, 31st August 2023 06:42:57
 * Modified By: Simon (s.gourlet@autobuy.fr>)
 * -----
 * Copyright 2022 - 2023 SAS Olivier Dubois, SAS Olivier Dubois
 */


.columns {
    display: flex;
    float: left;
    position: relative;
    margin-right: 1rem;
    /* column-count: 2;
    -webkit-column-count: 2;
    -moz-column-count: 2; */
}

.lights:first-child {
    margin-right: 0.5rem;
}

.bullet {
    flex: 0 0 40%;
    margin: 0.3rem 0;
    opacity: 0.4;
    border-radius: 999px;
    min-width: 0.5rem;
    max-width: 0.5rem;
    min-height: 0.5rem;
    max-height: 0.5rem;
}

.green {
    color: rgb(50, 205, 50);
    background-color: limegreen;
}

.orange {
    color: rgb(255, 165, 0);
    background-color: rgb(255, 165, 0)
}

.red {
    color: red;
    background-color: red;
}

.selected {
    opacity: 1;
}

.selected.red {
    box-shadow: 0 0 0.2rem 0.1rem rgba(255, 0, 0, 0.8),
    0 0 0.3rem 0.2rem rgba(255, 9, 113, 0.5),
    0 0 0.5rem 0.3rem rgba(255, 255, 255, 0.5);
}

.selected.orange {
    box-shadow: 0 0 0.2rem 0.1rem rgba(255, 165, 0, 0.8),
                0 0 0.3rem 0.2rem rgba(255, 165, 0, 0.5),
                0 0 0.5rem 0.3rem rgba(255, 255, 255, 1);

}

.selected.green {
    box-shadow: 0 0 0.2rem 0.1rem rgba(50, 205, 50, 0.8),
                0 0 0.3rem 0.2rem rgba(50, 205, 50, 0.5),
                0 0 0.5rem 0.3rem rgba(255, 255, 255, 1);
}
