/*
 * File: AddOfferOnCar.module.css
 * Project: autobuy-back-office
 * File Created: Monday, 9th October 2023 01:28:49
 * Author: Simon (s.gourlet@autobuy.fr)
 * -----
 * Last Modified: Friday, 19th January 2024 02:23:04
 * Modified By: Simon (s.gourlet@autobuy.fr>)
 * -----
 * Copyright 2022 - 2023 SAS Olivier Dubois, SAS Olivier Dubois
 */

.carOffers {
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
    height: 3rem;
}

.carOfferButton {
    text-wrap: wrap;
}

.carOfferButtonAlt {
    text-wrap: wrap;
    width: fit-content;
}
